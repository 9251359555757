<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/register.jpg" alt="register" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Account erstellen</h4>
                  <p>Erstelle dir nun ein Account.</p>
                </div>

                <div>
                  <vs-select
                    class="selectExample"
                    label="Anrede"
                    v-model="salutation"
                  >
                    <vs-select-item :is-selected.sync="item.isSelected" :key="index" :value="item.value" :text="item.label" v-for="item,index in salutations" />
                  </vs-select>

                  <div class="vx-row justify-between">
                    <div class="vx-col w-1/2">
                    <vs-input
                      v-validate="'required|min:3'"
                      name="firstname"
                      label-placeholder="Vorname"
                      v-model="firstname" class="w-full" />
                    <span class="text-danger text-sm">{{ errors.first('firstname') }}</span>
                    </div>
                      <div class="vx-col w-1/2">
                    <vs-input
                      v-validate="'required|min:3'"
                      name="lastname"
                      label-placeholder="Nachname"
                      v-model="lastname"
                      class="w-full" />
                    <span class="text-danger text-sm">{{ errors.first('lastname') }}</span>
                      </div>
                  </div>

                  <vs-input
                    v-validate="'required|email'"
                    name="email"
                    label-placeholder="Email"
                    v-model="email"
                    class="w-full"/>
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                  <vs-input
                    v-validate="'required|min:6'"
                    type="password"
                    name="password"
                    label-placeholder="Passwort"
                    v-model="password"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <div class="flex flex-wrap justify-between my-5">
                    <vs-button to="/login" type="border">Login</vs-button>
                    <vs-button :disabled="!validateForm" @click="register" class="float-right">Registrieren</vs-button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import ApiService from "../../api";

  export default {
    data() {
      return {
        email: "",
        password: "",
        checkbox_remember_me: false,
        lastname: '',
        firstname: '',
        salutation: 1,
        salutations: [
          {value: 1, label: 'Herr', isSelected: true},
          {value: 2, label: 'Frau', isSelected: false},
          {value: 3, label: 'Diverse', isSelected: false},
        ],
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email !== '' && this.password !== ''  && this.firstname !== '' && this.lastname !== '';
      },
    },
    methods: {
      register() {
        let me = this;

        if (!this.validateForm) return

        me.$vs.loading()

        const payload = {
          email: this.email,
          password: this.password,
          last_name: this.lastname,
          first_name: this.firstname,
        }

        ApiService.post('auth/create', payload).then((response) => {
          me.$vs.loading.close()
          me.$vs.notify({
            title: 'Erfolgreich',
            text: 'Ihr Account wurde erfolgreich angelegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })

          me.$router.push('/login');

        }).catch((response) => {
          me.$vs.loading.close();
          me.$vs.notify({
            title: 'Error',
            text: response.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });

      },
    }
  }
</script>

<style lang="scss">
</style>
